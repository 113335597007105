import { TagsEnum } from "@/lib/constants";
import type { ProjectData } from "@/types/componentsTypes";
import {
  GAZNNCardImgUrl,
  GAZNNSlideImgUrl,
  MirrplDPCardImgUrl,
  MirrplDPSlideImgUrl,
  OkkoCardImgUrl,
  OkkoSlideImgUrl,
  SqwozBabCardImgUrl,
  SqwozBabSlideImgUrl,
  Tele2TeatrCardImgUrl,
  Tele2TeatrSlideImgUrl,
  artLifeFestCardImgUrl,
  artLifeFestSlideImgUrl,
  codefestCardImgUrl,
  codefestSlideImgUrl,
  moskvariumCardImgUrl,
  moskvariumSlideImgUrl,
} from "./images";
import { RoutesEnum } from "./routes";

export enum ColorProjects {
  CLR_MAIN = "var(--clr-second)",
  CLR_CODEFEST = "#347FDA",
  CLR_MOSKVARIUM = "#FF1C45",
  CLR_SPACIBO = "#03BE72",
  CLR_OKKO = "#6600FF",
  CLR_GAZNN = "#22E8E0",
  CLR_SQWOZBAB = "#FF0032",
  CLR_MIRRPL_DP = "#137FE6",
}

export const links = [
  {
    name: "Главная",
    hrefLink: RoutesEnum.MAIN,
  },
  {
    name: "Проекты",
    hrefLink: `/${RoutesEnum.ALL_PROJECTS}`,
  },
  { name: "Сервисы", hrefLink: `/${RoutesEnum.SERVICES}` },
  { name: "Контакты", hrefLink: `/${RoutesEnum.CONTACTS}` },
] as const;

type ProjectName =
  | "CODEFEST"
  | "MOSKVARIUM"
  | "ARTFEST_LIFE"
  | "OKKO_STEREOLETO"
  | "GAZ_DK"
  | "SQWOZBAB_MTSMUSIC"
  | "TELE2_TEATR"
  | "MIRRPL_PROMO";

export const PROJECTS: Record<ProjectName, ProjectData> = {
  CODEFEST: {
    id: 1,
    brand: "МТС",
    type: "Десктопные приложения",
    name: "CodeFest",
    metadataName: "CodeFest",
    shortname: "МТС CodeFest",
    description:
      "Игровые механики для посетителей выставки. Отвечай на вопросы - получай призы!",
    releaseDate: new Date("2023-05-01"),
    tags: [
      TagsEnum.TOUCH,
      TagsEnum.WINDOWS_APP,
      TagsEnum.GAME,
      TagsEnum.UX_UI,
      TagsEnum.EXHIBITION,
    ],
    img: [codefestSlideImgUrl, codefestCardImgUrl],
    path: RoutesEnum.MTS_CODEFEST,
  },
  MOSKVARIUM: {
    id: 2,
    brand: "МТС",
    type: "Веб-приложение с дополненной реальностью",
    name: "Москвариум",
    metadataName: "Москвариум",
    shortname: "МТС JUNIOR Москвариум",
    description:
      "Увлекательные цифровые активации для юных посетителей Москвариума",
    releaseDate: new Date("2022-12-01"),
    tags: [TagsEnum.WEB, TagsEnum.AR, TagsEnum.GAME, TagsEnum.UX_UI],
    img: [moskvariumSlideImgUrl, moskvariumCardImgUrl],
    path: RoutesEnum.MTS_JUNIOR_MOSKVARIUM,
  },
  ARTFEST_LIFE: {
    id: 3,
    brand: "Сбер",
    type: "AI-приложение",
    name: "ARTLIFE FEST",
    metadataName: "ARTLIFE FEST",
    shortname: "СПАСИБО ARTLIFE FEST",
    description: "Десктопное приложение для генерации Арт-гороскопа",
    releaseDate: new Date("2022-09-01"),
    tags: [TagsEnum.TOUCH, TagsEnum.AI, TagsEnum.WINDOWS_APP],
    img: [artLifeFestSlideImgUrl, artLifeFestCardImgUrl],
    path: RoutesEnum.SPACIBO_ARTLIFE_FEST,
  },
  OKKO_STEREOLETO: {
    id: 4,
    brand: "OKKO",
    type: "Мультимедийная инсталляция",
    name: "OKKO STEREOLETO",
    metadataName: "OKKO STEREOLETO",
    shortname: "OKKO STEREOLETO",
    description: "Интерактивный контейнер предсказаний",
    releaseDate: new Date("2022-06-01"),
    tags: [
      TagsEnum.INSTALATION,
      TagsEnum.ARDUINO,
      TagsEnum.LED,
      TagsEnum.FESTIVAL,
    ],
    img: [OkkoSlideImgUrl, OkkoCardImgUrl],
    path: RoutesEnum.OKKO,
  },
  GAZ_DK: {
    id: 5,
    brand: "ГАЗ",
    type: "Веб и мобильное приложения",
    name: "Дилерская>конференция",
    metadataName: "Дилерская конференция ГАЗ",
    shortname: "Дилерская конференция",
    description:
      "Интерактивная демонстрация сервисов компании и преимуществ нового автомобиля ГАЗ",
    releaseDate: new Date("2021-06-01"),
    tags: [
      TagsEnum.WINDOWS_APP,
      TagsEnum.THREE_D,
      TagsEnum.UX_UI,
      TagsEnum.TOUCH,
      TagsEnum.PRESENTATION,
    ],
    img: [GAZNNSlideImgUrl, GAZNNCardImgUrl],
    path: RoutesEnum.GAZ_NN,
  },
  SQWOZBAB_MTSMUSIC: {
    id: 6,
    brand: "МТС Music",
    type: "Web",
    name: "SQWOZ BAB и МТС Music",
    metadataName: "SQWOZ BAB и МТС Music",
    shortname: "SQWOZ BAB и МТС Music",
    description: "Цифровой квест",
    releaseDate: new Date("2023-09-01"),
    tags: [TagsEnum.UX_UI, TagsEnum.WEB],
    img: [SqwozBabSlideImgUrl, SqwozBabCardImgUrl],
    path: RoutesEnum.SQWOZBAB,
  },
  TELE2_TEATR: {
    id: 7,
    brand: "ТЕЛЕ2",
    type: "AR фотоактивация",
    name: "ТЕЛЕ2 В театре на Бронной",
    metadataName: "ТЕЛЕ2 В театре на Бронной",
    shortname: "Tele2. AR фотоактивация",
    description: "AR фотоактивация с актерами театра",
    releaseDate: new Date("2024-09-01"),
    tags: [TagsEnum.AR, TagsEnum.WINDOWS_APP],
    img: [Tele2TeatrSlideImgUrl, Tele2TeatrCardImgUrl],
    path: RoutesEnum.TELE2_TEATR,
  },
  MIRRPL_PROMO: {
    id: 8,
    brand: "МИРРПЛ",
    type: "Web",
    name: "Диджитал промо",
    metadataName: "Диджитал промо МИРРПЛ",
    shortname: "Диджитал промо",
    description: "Промо-сайт и интерактивные решения",
    releaseDate: new Date("2024-10-01"),
    tags: [TagsEnum.UX_UI, TagsEnum.WEB, TagsEnum.ARDUINO, TagsEnum.CHAT_BOT],
    img: [MirrplDPSlideImgUrl, MirrplDPCardImgUrl],
    path: RoutesEnum.MIRRPL_DP,
  },
};

export const SORTED_PROJECTS: ProjectData[] = Object.values(PROJECTS).sort(
  (a: ProjectData, b: ProjectData) =>
    b.releaseDate.getTime() - a.releaseDate.getTime()
);
