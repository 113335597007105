"use client";

import {
	createContext,
	useContext,
	useEffect,
	useState,
	type ReactNode,
} from "react";

type Variant = "default" | "link" | "footer";

type CursorVariantContextProviderProps = {
	children: ReactNode;
};

type CursorVariantContext = {
	cursorVariant: string;
	linkEnter: () => void;
	linkEnterFooter: () => void;
	linkLeave: () => void;
};

export const CursorVariantContext = createContext<CursorVariantContext | null>(
	null,
);

export function CursorVariantContextProvider({
	children,
}: CursorVariantContextProviderProps) {
	const [cursorVariant, setCursorVariant] = useState<Variant>("default");

	const linkEnter = () => setCursorVariant("link");
	const linkEnterFooter = () => setCursorVariant("footer");
	const linkLeave = () => setCursorVariant("default");

	useEffect(() => {
		const handleRouteChange = () => {
			setCursorVariant("default");
		};
		
		window.addEventListener("popstate", handleRouteChange);

		return () => {
			window.removeEventListener("popstate", handleRouteChange);
		};
	}, []);

	return (
		<CursorVariantContext.Provider
			value={{
				cursorVariant,
				linkEnter,
				linkEnterFooter,
				linkLeave,
			}}
		>
			{children}
		</CursorVariantContext.Provider>
	);
}

export function useCursorVariantContext() {
	const context = useContext(CursorVariantContext);

	if (!context) {
		throw new Error(
			"useCursorVariantContext must be used within a CursorVariantContextProvider",
		);
	}
	return context;
}
