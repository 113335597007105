export enum RoutesEnum {
  MAIN = "/",
  ALL_PROJECTS = "projects",
  SERVICES = "services",
  CONTACTS = "contacts",
  MTS_CODEFEST = "mts-codefest",
  MTS_JUNIOR_MOSKVARIUM = "mts-junior-moskvarium",
  SPACIBO_ARTLIFE_FEST = "spacibo-artlife-fest",
  OKKO = "okko",
  GAZ_NN = "gaz-nn",
  SQWOZBAB = "sqwozbab",
  TELE2_TEATR = "tele2-teatr-na-bronnoy",
  MIRRPL_DP = "mirrpl-didzhital-promo",
}
