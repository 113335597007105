export enum TagsEnum {
  ALL_TAGS = "Все",
  TOUCH = "Touch",
  WINDOWS_APP = "Windows app",
  INSTALATION = "Инсталляции",
  GAME = "Игры",
  UX_UI = "UX/UI",
  EXHIBITION = "Выставки",
  WEB_AR = "WebAR",
  THREE_D = "3D",
  PHOTO = "Photo",
  AI = "AI",
  WEB = "Web",
  FESTIVAL = "Фестивали",
  PRESENTATION = "Презентации",
  ARDUINO = "Arduino",
  LED = "Led",
  AR = "AR",
  CHAT_BOT = "Чат-бот",
}

export const filterByTechnology = [
  { id: 1, name: TagsEnum.ALL_TAGS },
  { id: 2, name: TagsEnum.WEB },
  { id: 3, name: TagsEnum.UX_UI },
  { id: 4, name: TagsEnum.AR },
  { id: 5, name: TagsEnum.AI },
  {
    id: 6,
    name: TagsEnum.WINDOWS_APP,
  },
  { id: 7, name: TagsEnum.INSTALATION },
];
