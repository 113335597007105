import codefestCardImgUrl from "@/images/projects/CodeFest-Card.webp";
import codefestSlideImgUrl from "@/images/projects/CodeFest-Slide.webp";
import GAZNNCardImgUrl from "@/images/projects/GAZNN-Card.webp";
import GAZNNSlideImgUrl from "@/images/projects/GAZNN-Slide.webp";
import MirrplDPCardImgUrl from "@/images/projects/MirrplDP-Card.webp";
import MirrplDPSlideImgUrl from "@/images/projects/MirrplDP-Slide.webp";
import moskvariumCardImgUrl from "@/images/projects/Moskvarium-Card.webp";
import moskvariumSlideImgUrl from "@/images/projects/Moskvarium-Slide.webp";
import OkkoCardImgUrl from "@/images/projects/Okko-Card.webp";
import OkkoSlideImgUrl from "@/images/projects/Okko-Slide.webp";
import artLifeFestCardImgUrl from "@/images/projects/SpaciboArtlifeFest-Card.webp";
import artLifeFestSlideImgUrl from "@/images/projects/SpaciboArtlifeFest-Slide.webp";
import SqwozBabCardImgUrl from "@/images/projects/SqwozBab-Card.webp";
import SqwozBabSlideImgUrl from "@/images/projects/SqwozBab-Slide.webp";
import Tele2TeatrCardImgUrl from "@/images/projects/Tele2Teatr-Card.webp";
import Tele2TeatrSlideImgUrl from "@/images/projects/Tele2Teatr-Slide.webp";

import ArtfestSpaciboImg1 from "@/images/projects/spacibo-artlife-fest/1-1.webp";
import ArtfestSpaciboImg2 from "@/images/projects/spacibo-artlife-fest/1-2.webp";
import ArtfestSpaciboImg3 from "@/images/projects/spacibo-artlife-fest/1-3.webp";
import ArtfestSpaciboImg4 from "@/images/projects/spacibo-artlife-fest/2-1.webp";
import ArtfestSpaciboImg5 from "@/images/projects/spacibo-artlife-fest/2-2.webp";
import ArtfestSpaciboImg6 from "@/images/projects/spacibo-artlife-fest/2-3.webp";
import ArtfestSpaciboImg7 from "@/images/projects/spacibo-artlife-fest/3-1.webp";
import ArtfestSpaciboImg8 from "@/images/projects/spacibo-artlife-fest/3-2.webp";
import ArtfestSpaciboImg9 from "@/images/projects/spacibo-artlife-fest/3-3.webp";
import ArtfestSpaciboImg10 from "@/images/projects/spacibo-artlife-fest/3-4.webp";
import ArtfestSpaciboImg11 from "@/images/projects/spacibo-artlife-fest/3-5.webp";
import ArtfestSpaciboImg12 from "@/images/projects/spacibo-artlife-fest/4-1.webp";
import ArtfestSpaciboImg13 from "@/images/projects/spacibo-artlife-fest/4-2.webp";
import ArtfestSpaciboImg14 from "@/images/projects/spacibo-artlife-fest/4-3.webp";
import ArtfestSpaciboImg15 from "@/images/projects/spacibo-artlife-fest/4-4.webp";
import ArtfestSpaciboImg16 from "@/images/projects/spacibo-artlife-fest/4-5.webp";

import ArtfestSpaciboMobileImg1 from "@/images/projects/spacibo-artlife-fest/1_1.webp";
import ArtfestSpaciboMobileImg2 from "@/images/projects/spacibo-artlife-fest/1_2.webp";
import ArtfestSpaciboMobileImg3 from "@/images/projects/spacibo-artlife-fest/1_3.webp";
import ArtfestSpaciboMobileImg4 from "@/images/projects/spacibo-artlife-fest/2_1.webp";
import ArtfestSpaciboMobileImg5 from "@/images/projects/spacibo-artlife-fest/2_2.webp";
import ArtfestSpaciboMobileImg6 from "@/images/projects/spacibo-artlife-fest/2_3.webp";
import ArtfestSpaciboMobileImg7 from "@/images/projects/spacibo-artlife-fest/3_1.webp";
import ArtfestSpaciboMobileImg8 from "@/images/projects/spacibo-artlife-fest/3_2.webp";
import ArtfestSpaciboMobileImg9 from "@/images/projects/spacibo-artlife-fest/3_3.webp";
import ArtfestSpaciboMobileImg10 from "@/images/projects/spacibo-artlife-fest/3_4.webp";
import ArtfestSpaciboMobileImg11 from "@/images/projects/spacibo-artlife-fest/3_5.webp";
import ArtfestSpaciboMobileImg12 from "@/images/projects/spacibo-artlife-fest/4_1.webp";
import ArtfestSpaciboMobileImg13 from "@/images/projects/spacibo-artlife-fest/4_2.webp";
import ArtfestSpaciboMobileImg14 from "@/images/projects/spacibo-artlife-fest/4_3.webp";
import ArtfestSpaciboMobileImg15 from "@/images/projects/spacibo-artlife-fest/4_4.webp";
import ArtfestSpaciboMobileImg16 from "@/images/projects/spacibo-artlife-fest/4_5.webp";

import OkkoImg1 from "@/images/projects/okko/1-1.webp";
import OkkoImg2 from "@/images/projects/okko/1-2.webp";
import OkkoImg3 from "@/images/projects/okko/1-3.webp";
import OkkoImgMobile1 from "@/images/projects/okko/1_1.webp";
import OkkoImgMobile2 from "@/images/projects/okko/1_2.webp";
import OkkoImgMobile3 from "@/images/projects/okko/1_3.webp";

import GazNNImg1 from "@/images/projects/gaz-nn/1-1.webp";
import GazNNImg2 from "@/images/projects/gaz-nn/1-2.webp";
import GazNNImg3 from "@/images/projects/gaz-nn/1-3.webp";
import GazNNImg4 from "@/images/projects/gaz-nn/1-4.webp";
import GazNNImg5 from "@/images/projects/gaz-nn/2-1.webp";
import GazNNImg6 from "@/images/projects/gaz-nn/2-2.webp";

import MirRPLDPImg1 from "@/images/projects/mirrpl-didzhital-promo/1_1.webp";
import MirRPLDPImg2 from "@/images/projects/mirrpl-didzhital-promo/1_2.webp";
import MirRPLDPImg3 from "@/images/projects/mirrpl-didzhital-promo/1_3.webp";
import MirRPLDPImg4 from "@/images/projects/mirrpl-didzhital-promo/1_4.webp";
import MirRPLDPImg5 from "@/images/projects/mirrpl-didzhital-promo/2_1.webp";
import MirRPLDPImg6 from "@/images/projects/mirrpl-didzhital-promo/2_2.webp";
import MirRPLDPImg7 from "@/images/projects/mirrpl-didzhital-promo/2_3.webp";
import MirRPLDPImg8 from "@/images/projects/mirrpl-didzhital-promo/2_4.webp";
import MirRPLDPImg9 from "@/images/projects/mirrpl-didzhital-promo/2_5.webp";
import MirRPLDPImg10 from "@/images/projects/mirrpl-didzhital-promo/3_1.webp";
import MirRPLDPImg11 from "@/images/projects/mirrpl-didzhital-promo/3_2.webp";
import MirRPLDPImg12 from "@/images/projects/mirrpl-didzhital-promo/3_3.webp";
import MirRPLDPImg13 from "@/images/projects/mirrpl-didzhital-promo/3_4.webp";
import MirRPLDPImg14 from "@/images/projects/mirrpl-didzhital-promo/3_5.webp";
import MirRPLDPImg15 from "@/images/projects/mirrpl-didzhital-promo/3_6.webp";
import MirRPLDPImg16 from "@/images/projects/mirrpl-didzhital-promo/3_7.webp";
import MirRPLDPImg17 from "@/images/projects/mirrpl-didzhital-promo/3_8.webp";
import MirRPLDPImg18 from "@/images/projects/mirrpl-didzhital-promo/3_9.webp";

export {
  GAZNNCardImgUrl,
  GAZNNSlideImgUrl,
  MirrplDPCardImgUrl,
  MirrplDPSlideImgUrl,
  OkkoCardImgUrl,
  OkkoImg1,
  OkkoImg2,
  OkkoImg3,
  OkkoImgMobile1,
  OkkoImgMobile2,
  OkkoImgMobile3,
  OkkoSlideImgUrl,
  SqwozBabCardImgUrl,
  SqwozBabSlideImgUrl,
  Tele2TeatrCardImgUrl,
  Tele2TeatrSlideImgUrl,
  artLifeFestCardImgUrl,
  artLifeFestSlideImgUrl,
  codefestCardImgUrl,
  codefestSlideImgUrl,
  moskvariumCardImgUrl,
  moskvariumSlideImgUrl,
};

export const IMAGES_SPACIBO_ARTLIFE_FEST = {
  firstSection: [
    {
      id: 1,
      width: 712,
      height: 400,
      src: ArtfestSpaciboImg1,
      srcMobile: ArtfestSpaciboMobileImg1,
      alt: "",
    },
    {
      id: 2,
      width: 712,
      height: 400,
      src: ArtfestSpaciboImg2,
      srcMobile: ArtfestSpaciboMobileImg2,
      alt: "",
    },
    {
      id: 3,
      width: 1096,
      height: 834,
      src: ArtfestSpaciboImg3,
      srcMobile: ArtfestSpaciboMobileImg3,
      alt: "",
    },
  ],
  secondSection: [
    {
      id: 1,
      width: 1096,
      height: 834,
      src: ArtfestSpaciboImg4,
      srcMobile: ArtfestSpaciboMobileImg4,
      alt: "",
    },
    {
      id: 2,
      width: 712,
      height: 400,
      src: ArtfestSpaciboImg5,
      srcMobile: ArtfestSpaciboMobileImg5,
      alt: "",
    },
    {
      id: 3,
      width: 712,
      height: 400,
      src: ArtfestSpaciboImg6,
      srcMobile: ArtfestSpaciboMobileImg6,
      alt: "",
    },
  ],
  thirdSection: [
    {
      id: 1,
      width: 712,
      height: 400,
      src: ArtfestSpaciboImg7,
      srcMobile: ArtfestSpaciboMobileImg7,
      alt: "",
    },
    {
      id: 2,
      width: 712,
      height: 400,
      src: ArtfestSpaciboImg8,
      srcMobile: ArtfestSpaciboMobileImg8,
      alt: "",
    },
    {
      id: 3,
      width: 1096,
      height: 834,
      src: ArtfestSpaciboImg9,
      srcMobile: ArtfestSpaciboMobileImg9,
      alt: "",
    },
    {
      id: 4,
      width: 1096,
      height: 400,
      src: ArtfestSpaciboImg10,
      srcMobile: ArtfestSpaciboMobileImg10,
      alt: "",
    },
    {
      id: 5,
      width: 712,
      height: 400,
      src: ArtfestSpaciboImg11,
      srcMobile: ArtfestSpaciboMobileImg11,
      alt: "",
    },
  ],
  fourthSection: [
    {
      id: 1,
      width: 567,
      height: 1201,
      src: ArtfestSpaciboImg12,
      srcMobile: ArtfestSpaciboMobileImg12,
      alt: "",
    },
    {
      id: 2,
      width: 798.83,
      height: 581.07,
      src: ArtfestSpaciboImg13,
      srcMobile: ArtfestSpaciboMobileImg13,
      alt: "",
    },
    {
      id: 3,
      width: 406.9,
      height: 581.07,
      src: ArtfestSpaciboImg14,
      srcMobile: ArtfestSpaciboMobileImg14,
      alt: "",
    },
    {
      id: 4,
      width: 406.9,
      height: 581.07,
      src: ArtfestSpaciboImg15,
      srcMobile: ArtfestSpaciboMobileImg15,
      alt: "",
    },
    {
      id: 5,
      width: 798.83,
      height: 581.07,
      src: ArtfestSpaciboImg16,
      srcMobile: ArtfestSpaciboMobileImg16,
      alt: "",
    },
  ],
};

export const IMAGES_OKKO = [
  {
    id: 1,
    width: 1096,
    height: 834,
    src: OkkoImg3,
    srcMobile: OkkoImgMobile3,
    alt: "",
  },
  {
    id: 2,
    width: 712,
    height: 400,
    src: OkkoImg1,
    srcMobile: OkkoImgMobile1,
    alt: "",
  },
  {
    id: 3,
    width: 712,
    height: 400,
    src: OkkoImg2,
    srcMobile: OkkoImgMobile2,
    alt: "",
  },
];

export const IMAGES_GAZ_NN = {
  firstSection: [
    {
      id: 1,
      width: 910,
      height: 514,
      src: GazNNImg1,
      srcMobile: GazNNImg1,
      alt: "",
    },
    {
      id: 2,
      width: 910,
      height: 514,
      src: GazNNImg2,
      srcMobile: GazNNImg2,
      alt: "",
    },
    {
      id: 3,
      width: 910,
      height: 514,
      src: GazNNImg3,
      srcMobile: GazNNImg3,
      alt: "",
    },
    {
      id: 4,
      width: 910,
      height: 514,
      src: GazNNImg4,
      srcMobile: GazNNImg4,
      alt: "",
    },
  ],
  secondSection: [
    {
      id: 1,
      width: 910,
      height: 514,
      src: GazNNImg5,
      srcMobile: GazNNImg5,
      alt: "",
    },
    {
      id: 2,
      width: 910,
      height: 514,
      src: GazNNImg6,
      srcMobile: GazNNImg6,
      alt: "",
    },
  ],
};

export const IMAGES_MIRRPL_DP = {
  firstSlides: [
    { id: 1, src: MirRPLDPImg1 },
    { id: 2, src: MirRPLDPImg2 },
    { id: 3, src: MirRPLDPImg3 },
    { id: 4, src: MirRPLDPImg4 },
  ],
  secondSlides: [
    { id: 1, src: MirRPLDPImg5 },
    { id: 2, src: MirRPLDPImg6 },
    { id: 3, src: MirRPLDPImg7 },
    { id: 4, src: MirRPLDPImg8 },
    { id: 5, src: MirRPLDPImg9 },
  ],
  thirdSlides: [
    { id: 1, src: MirRPLDPImg10 },
    { id: 2, src: MirRPLDPImg11 },
    { id: 3, src: MirRPLDPImg12 },
    { id: 4, src: MirRPLDPImg13 },
    { id: 5, src: MirRPLDPImg14 },
    { id: 6, src: MirRPLDPImg15 },
    { id: 7, src: MirRPLDPImg16 },
    { id: 8, src: MirRPLDPImg17 },
    { id: 9, src: MirRPLDPImg18 },
  ],
};
